import { Button } from '@mui/material';
import MicrosoftLogo from './icons/microsoft.png';
import GoogleLogo from './icons/google.png';
import AmazonLogo from './icons/amazon.png';
import OpenAILogo from './icons/openai.png';

export const Window = () => {
  const title = () => {
    return (
      <>
        <div className="text-center text-4xl sm:text-7xl">
          Cut discovery costs for your legal practice by 50%!
        </div>
      </>
    );
  };

  const cta = () => {
    return (
      <div className="flex flex-col items-center justify-center">
        <div className="mx-0 mt-8 text-center text-lg sm:mx-40 sm:mt-20 sm:text-2xl">
          Better than your expensive eDiscovery software that’s difficult to
          use. Expand your practice without increasing headcount.
        </div>
        <div className="font-pt mt-8 text-center">
          Now open for early access. Currently limited to litigators in the Bay
          Area.
        </div>
        <div className="margin-auto font-urbanist my-8">
          <Button
            variant="contained"
            href="https://81k0iibzjo9.typeform.com/to/GCKNK46Z"
          >
            <div className="text-sm sm:text-2xl">
              Apply for Early Access 2023
            </div>
          </Button>
        </div>
        <div className="font-pt sm:text-md text-xs">
          Built in partnership with engineers from
        </div>
        <div className="mb-8 mt-8 flex flex-col items-center gap-y-4 sm:mt-0 sm:flex-row sm:gap-x-8">
          <div className="flex w-40 items-center">
            <img src={MicrosoftLogo} alt="Microsoft" />
          </div>
          <div className="flex w-32 items-center sm:mt-2">
            <img src={GoogleLogo} alt="Google" />
          </div>
          <div className="flex w-32 items-center sm:mt-6">
            <img src={AmazonLogo} alt="Amazon" />
          </div>
          <div className="flex w-40 items-center sm:mt-2">
            <img src={OpenAILogo} alt="OpenAI" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <header className="sticky top-0 z-50">
        <div className="mx-12 mb-12 pt-8 sm:mx-20">
          <div className="font-urbanist w-48 text-xl sm:text-4xl">PEGASUS</div>
        </div>
      </header>
      <div className="mx-12 sm:mx-80">
        <div className="flex flex-col justify-center">
          <div>{title()}</div>
          <div>{cta()}</div>
        </div>
      </div>
    </div>
  );
};
