import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Window } from './Window';

const theme = createTheme({
  palette: {
    background: {
      default: '#1F2124',
    },
    text: {
      primary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'PT Serif, serif',
    h1: {
      fontSize: 96,
    },
    h2: {
      fontSize: 40,
    },
    h3: {
      fontSize: 32,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#1F2124',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#E0E0E0',
          },
          '&:active': {
            backgroundColor: '#BDBDBD',
          },
          fontFamily: 'DM Sans, sans-serif',
          fontSize: 24,
          textTransform: 'none',
          padding: '16px 48px',
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Window />
    </ThemeProvider>
  );
}

export default App;
